<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ title }}
        </b-card-title>
        <b-card-sub-title>{{ data.year }} Yılı Hedef ve Satış Grafiği</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1">
          {{ data.total_sales }} TL / {{ data.total_targets }} TL
        </h5>

      </div>
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="300"
        :options="targetChart.chartOptions"
        :series="seriesData"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import staticParams from '../../../../static-params'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      targetChart: {
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning, $themeColors.success],
          },
          colors: [$themeColors.warning, $themeColors.success],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                staticParams.formatCurrency(data.series[data.seriesIndex][data.dataPointIndex], 2)
              }TL</span></div>`
            },
          },
          xaxis: {
            categories: [
              'Ocak',
              'Şubat',
              'Mart',
              'Nisan',
              'Mayıs',
              'Haziran',
              'Temmuz',
              'Ağustos',
              'Eylül',
              'Ekim',
              'Kasım',
              'Aralık',
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    }
  },
  computed: {
    staticParams() {
      return this.$staticParams
    },
    seriesData() {
      const sales = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      const targets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

      this.data.monthly_sales_and_targets.forEach(val => {
        val.target_list.forEach((val2, index) => {
          sales[index] += val2.sales
          targets[index] += val2.target
        })
      })
      const seriesData = [
        {

          name: 'Hedef',
          data: targets,
        },
        {

          name: 'Satış',
          data: sales,
        },
      ]
      return seriesData
    },
  },
  created() {
  },
}
</script>
